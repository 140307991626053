'use client';
import { Flex, Button,  HStack , chakra ,Link, Box, useAccordion,useColorModeValue } from '@chakra-ui/react';
import Image from 'next/image';
import { useState, useEffect } from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp,  FaYoutube } from "react-icons/fa";
import '../app/globals.css';
import React from "react";
import MobileDrawer from './Mobile';
import { usePathname, useRouter } from 'next/navigation';


const NavBarIwin = (props) => {
  const { push } = useRouter();
  const  path= usePathname();
  const [isScrolled, setIsScrolled] = useState(false);



useEffect(() => {
  let prevScrollY =  window.scrollY;

  const handleScroll = () => {
    const currentScrollY =  window.scrollY;

    if (currentScrollY > 5 && !isScrolled) {
      setIsScrolled(true);
    } else if (currentScrollY <= 10 && isScrolled) {
      setIsScrolled(false);
    }

    prevScrollY = currentScrollY;
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, [isScrolled]);

const backgroundColor =  isScrolled  ? '#ffffff' : 'transparent';

 const burgerColor=isScrolled? 'black !important': '#ffffff'


const logoSrc = isScrolled ?  '/img/iwin dark.png' : '/img/iwin.png';

const textColor= isScrolled? 'black': 'white';


const iwinPhone=5491122604444;
  const message="Hola, me contacto desde Interwin web...";
  const fullMessage = `${message} `;
  const whatsappUrl = `https://wa.me/${iwinPhone}?text=${encodeURIComponent(fullMessage)}`;



  

  return (
    
    <Flex 
    
    w='100vw'
    px="6"
    py="5"
    pt='30px'
    align="center"
    justifyContent={{ base: 'center', sm: 'space-between' }}
   
    zIndex={1000}
    h={'90px'}
    position={props.pos}
   bgColor={backgroundColor}
   transition="background-color 0.5s ease"
    
  >
<div class="ml-0 sm:ml-6 cursor-pointer">

<Image src={path==="/"|| path=="/home" ?logoSrc:'/img/iwin dark.png'} alt={'logo interwin'} height={45} width={171}  onClick={() => { push('/'); }} 
></Image>
</div>


    
<Box display={{ base: "none", sm:'none', md:'none' ,lg: "flex", xl: "flex","2xl":"flex"}} mt='.6rem'>


    <HStack as="nav" mr={'40px'} spacing='2px' >

    
 
          <Button color={path==="/"|| path=="/home" ? textColor :'black'} _hover={{color:'#e1a360'}} mx={'5px'}  variant="nav"  fontWeight={400} onClick={()=>{ push('/');}}
            fontFamily="Heebo, sans-serif" fontSize={'15px'}> BUSCADOR</Button>
    
         
          <Button color={path==="/"|| path=="/home" ? textColor :'black'} _hover={{color:'#e1a360'}} mx={'5px'} variant="nav"  fontWeight={400} onClick={()=>{ push('/emprendimientos');}}
            fontFamily="Heebo, sans-serif" fontSize={'15px'}> EMPRENDIMIENTOS</Button>

          <Button color={path==="/"|| path=="/home" ? textColor :'black'} _hover={{color:'#e1a360'}}  variant="nav" fontWeight={400} onClick={()=>{ push('/inversiones');}}
            fontFamily="Heebo, sans-serif" fontSize={{lg:'15px', md:'12px'}}> INVERSIONES</Button>

       

          <Button  color={path==="/"|| path=="/home" ? textColor :'black'} _hover={{color:'#e1a360'}}   variant="nav"  fontWeight={400} onClick={()=>{ push('/tasaciones');}}
            fontFamily="Heebo, sans-serif" fontSize={'15px'}> TASACIONES</Button>
       
        
          <Button  color={path==="/"|| path=="/home" ? textColor :'black'} _hover={{color:'#e1a360'}} variant="nav"  fontWeight={400} onClick={()=>{ push('/empresa');}}
            fontFamily="Heebo, sans-serif" fontSize={'15px'}> EMPRESA</Button>
  
          
          <Button  color={path==="/"|| path=="/home" ? textColor :'black'} _hover={{color:'#e1a360'}} variant="nav"  fontWeight={400} onClick={()=>{ push('/contacto-iw');}}
            fontFamily="Heebo, sans-serif" fontSize={'15px'}> CONTACTO</Button>
    
          
    </HStack>


    <HStack as="nav" mr='10px'  color={path==="/"|| path=="/home" ? textColor :'black !important'}>
    <Link lineHeight={'none'}  _hover={{color:'#e1a360'}} href='https://es-la.facebook.com/interwin.itw/' target='_blank' rel='noopener noreferrer'>
    <Button p='0' variant="nav"> <FaFacebook style={{ fontSize: '14px' }}/></Button>
        </Link>
        <Link lineHeight={'none'} _hover={{color:'#e1a360'}} href='https://www.instagram.com/interwin.itw/?hl=es' target='_blank' rel='noopener noreferrer'>
          <Button p='0' variant="nav"><FaInstagram style={{ fontSize: '14px' }}/></Button>
        </Link>
        <Link lineHeight={'none'} _hover={{color:'#e1a360'}} href='https://ar.linkedin.com/company/interwin-marketing-inmobiliario' target='_blank' rel='noopener noreferrer' >
          <Button p='0' variant="nav"><FaLinkedin style={{ fontSize: '14px' }}/></Button>
        </Link>
        <Link lineHeight={'none'} _hover={{color:'#e1a360'}} href={whatsappUrl} target='_blank'>
          <Button p='0' variant="nav">< FaWhatsapp style={{ fontSize: '14px' }}/></Button>
        </Link>
        <Link lineHeight={'none'} _hover={{color:'#e1a360'}} href='https://www.youtube.com/interwinargentina' target='_blank'>
          <Button p='0' variant="nav"><FaYoutube style={{ fontSize: '14px' }}/></Button>
        </Link>
    </HStack>
    </Box>

   

    <Box  display={{ base: "flex", sm:"flex", md:"flex" ,lg: "none", xl: "none","2xl":"none"}}  position="absolute" mt='10px' right="8px" >

    <MobileDrawer  burgerColor={path==="/"|| path=="/home" ? burgerColor: 'black !important'}/>
    </Box>
  

    
  </Flex>
  )
}

export default NavBarIwin


